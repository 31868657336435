import * as React from 'react';
import { useContext, useEffect } from 'react';
import { Card, CardContent, Container, Grid } from '@material-ui/core';
import {
  Button,
  ButtonProps,
  ErrorCommon,
  FormikValues,
  FormWrap,
  Label,
  TextField,
  TextFieldCommon
} from '../components/formikFormInputs';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useAuth } from '../lib/useAuth';
import { useRouter } from 'next/router';
import Layout from '../components/Layout';
import { required } from '../utils/validation';
import { useLogin, useSessionLogin } from '../lib/useLogin';
import { ADMIN_ROLES, AUTH_SESSION_KEY } from '../utils/constants';
import { save } from '../utils/storage';
import { refreshAccessToken } from '../lib/privateApi';
import Loader from '../components/Loader';
import { handleFormError } from '../utils/commonUtils';
import { SnackBarSeverity } from '../utils/enums';
import { SnackbarContext } from '../components/Snackbar/Context';

const LoginForm: React.FC<FormikValues> = (loginProps: FormikValues) => {
  const {errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values} = loginProps;
  const fieldObj = [
    {
      id: 'email',
      name: 'email',
      component: TextField,
      placeholder: 'Email',
      validate: required,
      label: <Label label={'Email'} required={true} />
    }, {
      id: 'password',
      name: 'password',
      component: TextField,
      placeholder: 'Password',
      validate: required,
      type: 'password',
      label: <Label label={'Password'} required={true} />
    }
  ];
  const loginSubmitProps: ButtonProps = {
    className: 'mt20 mb20 floatRight ',
    color: 'primary',
    disabled: isSubmitting,
    endIcon: (<ArrowForwardIcon />),
    label: 'Login',
    loading: isSubmitting,
    type: 'submit',
    variant: 'contained',
  };

  return (
    <Card>
      <CardContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            {
              fieldObj.map((field, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    <TextFieldCommon {...field} values={values} onChange={handleChange} onBlur={handleBlur} />
                    <ErrorCommon errors={errors} name={field.name} touched={touched} />
                  </Grid>
                )
              })
            }
          </Grid>
          <Button {...loginSubmitProps} />
        </form>
      </CardContent>
    </Card>
  );
};

function Login() {
  const initialValues: any = {
    email: '',
    password: ''
  };
  const {user, mutateUser} = useAuth();
  const router = useRouter();
  const snackbar = useContext(SnackbarContext);
  const onSubmit = async ({email, password}: FormikValues, {resetForm, setErrors}: FormikValues) => {
    try {
      const {accessToken, user} = await useLogin({email, password, role: ADMIN_ROLES});
      if (ADMIN_ROLES.includes(user.role)) {
        await useSessionLogin(user);
        await mutateUser(user);
        await save(AUTH_SESSION_KEY, accessToken);
        refreshAccessToken((a: string) => snackbar.showMessage(a, SnackBarSeverity.Error));
      }
      await router.push('/');
      resetForm();
      // @ts-ignore TS1196: Catch clause variable cannot have a type annotation.
    } catch (err: any) {
      const errMessage = handleFormError(err);
      setErrors({email: "Invalid email or password"});
      snackbar.showMessage(errMessage, SnackBarSeverity.Error);
    }
  };
  const _formikProps = {
    children: LoginForm,
    initialValues: initialValues,
    onSubmit
  };

  useEffect(() => {
    if (user && user.isLoggedIn) {
      router.push('/').then();
    }
  }, [user]);

  if (user && user.isLoggedIn) {
    return <Loader />;
  }

  return (
    <Layout title="Login" keyword={'Login'}>
      <Container maxWidth="xl" className="heroContent">
        <Grid container justifyContent="center">
          <Grid item xs={12} md={4} className="textCenter">
            <h2>Login</h2>
            <FormWrap {..._formikProps} />
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export default Login;
