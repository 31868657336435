import { unmaskPhoneNumber } from './commonUtils';

export const REQUIRED = 'Required';

export const required = (val: any) => {
    let error;
    if (!val) {
        error = REQUIRED;
    }
    // else if(val.trim()===""){
    //     error = REQUIRED;
    // }
    return error;
};
export const email = (val: any) => {
    let error;
    if (!val) {
        error = REQUIRED;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val)) {
        error = 'Invalid email address';
    }
    return error;
};

export const phoneNumber = (val: any) => {
    let error;
    if (!val) {
        error = REQUIRED;
    } else if(unmaskPhoneNumber(val).length < 10){
        error = "Invalid Phone Number";
    }
    return error
};

export const age = (val: any) => {
    let error;
    if (!val) {
        error = REQUIRED;
    } else if(Number(val) <= 0){
        error = "Invalid Age";
    }
    return error
};
export const requiredSelectBox = (val: any) => {
    let error;
    if (!val) {
        error = REQUIRED;
    }
    return error;
};
